// svg import is typed any:
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import ChevronUp from 'public/icons/chevron-up.svg'
import Close from 'public/icons/close.svg'
import Sort from 'public/icons/sort.svg'
import SortDesc from 'public/icons/sort-desc.svg'
import SortAsc from 'public/icons/sort-asc.svg'
import ChevronDown from 'public/icons/chevron-down.svg'
import Hamburger from 'public/icons/hamburger.svg'
import Plus from 'public/icons/plus.svg'
import SoundOn from 'public/icons/sound-on.svg'
import SoundOff from 'public/icons/sound-off.svg'
import CameraOn from 'public/icons/camera-on.svg'
import CameraOff from 'public/icons/camera-off.svg'
import Phone from 'public/icons/phone.svg'
import Email from 'public/icons/email.svg'
import Edit from 'public/icons/edit.svg'
import Check from 'public/icons/check.svg'
import Error from 'public/icons/error.svg'
import ArrowBack from 'public/icons/arrow-back.svg'
import ArrowRight from 'public/icons/arrow-right.svg'
import ArrowDown from 'public/icons/arrow-down.svg'
import Loading from 'public/icons/loading.svg'
import {c} from 'utils/className'
import styles from './Icon.module.scss'

const ICON_COMPONENTS = {
  close: Close,
  sort: Sort,
  sortDesc: SortDesc,
  sortAsc: SortAsc,
  chevronUp: ChevronUp,
  chevronDown: ChevronDown,
  hamburger: Hamburger,
  plus: Plus,
  soundOn: SoundOn,
  soundOff: SoundOff,
  cameraOn: CameraOn,
  cameraOff: CameraOff,
  edit: Edit,
  phone: Phone,
  email: Email,
  check: Check,
  error: Error,
  arrowBack: ArrowBack,
  arrowRight: ArrowRight,
  arrowDown: ArrowDown,
  loading: Loading
}

export type IconType = keyof typeof ICON_COMPONENTS

type Props = {
  type: IconType
  color?: 'textDark' | 'textLight' | 'systemDark' | 'warning' | 'onWarning'
  size: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl'
}

const Icon = ({type, color = 'textDark', size}: Props) => {
  const Component = ICON_COMPONENTS[type]

  return <Component className={c(styles[color], styles[size])} />
}

export default Icon
