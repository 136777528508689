export const nl = {
  /* ===================================================
       General
     =================================================== */

  global: {
    continue: 'Doorgaan',
    confirm: 'Bevestigen',
    back: 'Terug',
    loading: 'Bezig...',
    close: 'Sluiten',
    cancel: 'Annuleren',
    edit: 'Bewerken',
    create: 'Aanmaken',
    delete: 'Verwijderen',
    finish: 'Voltooien',
    error: 'Error',
    save: 'Opslaan',
    invalid: 'Ongeldig',
    ok: 'OK',
    more: 'Meer',
    sort: 'Sorteren',
    sort_by: 'Sorteren op {{sortBy}}'
  },

  nav: {
    orders: 'Orders',
    customers: 'Klanten',
    bags: 'Bags',
    return_locations: 'Inleverpunten',
    activity: 'Activiteit',
    settings: 'Instellingen',
    scanner: 'Scanner',
    scanner_icon_alt: 'Scanner'
  },

  errors: {
    error_general: 'Er is iets fout gegaan.',
    error_connection:
      'Er is iets fout gegaan. Controleer je internetverbinding en probeer het opnieuw.',
    error_invalid_qr: 'Ongeldige QR.',
    error_not_found: 'Niet gevonden.'
  },

  bag_status: {
    awaiting_production: 'Wacht op productie',
    ready: 'Klaar voor gebruik',
    in_set: 'In set',
    in_order: 'In order',
    in_operation: 'In gebruik',
    returned: 'Bij inleverpunt',
    dirty: 'Schoonmaak nodig',
    broken: 'Reparatie nodig',
    end_of_life: 'Afgeschreven'
  },

  bag_condition: {
    ok: 'Klaar voor gebruik',
    dirty: 'Schoonmaak nodig',
    broken: 'Reparatie nodig'
  },

  order_status: {
    created: 'Aangemaakt',
    packed: 'Ingepakt',
    sent: 'Verzonden'
  },

  /* ===================================================
       Components
     =================================================== */

  //  TODO: move strings for Table components out of pages objects (eg. BagsTable out of bags page)

  combo_box: {
    open_menu: 'Open menu',
    close_menu: 'Sluit menu'
  },

  formatted_location: {
    call: 'Bellen',
    send_email: 'E-mailen'
  },

  editable_location: {
    name: 'Naam locatie',
    street: 'Straat',
    house_number: 'Huisnummer',
    postal_code: 'Postcode',
    city: 'Stad',
    contact_name: 'Naam contactpersoon',
    phone_number: 'Telefoonnummer',
    email_address: 'E-mailadres',
    not_found: 'Niet gevonden',
    too_many_results: 'Teveel resultaten',
    incomplete_address: 'Geen adres gevonden. Controleer postcode en huisnummer en probeer opnieuw.'
  },

  sound_button: {
    enable: 'Geluid inschakelen',
    disable: 'Geluid uitschakelen'
  },

  camera_button: {
    enable: 'Camera inschakelen',
    disable: 'Camera uitschakelen'
  },

  scan_process: {
    progress: '{{scanned}}/{{total}} gescand'
  },

  bag_events_table: {
    sent_to: 'Verzonden naar ',
    created: 'Bag aangemaakt'
  },

  /* ===================================================
       Pages
     =================================================== */

  login: {
    title: 'Inloggen',
    email: 'E-mail',
    password: 'Wachtwoord',
    submit: 'Inloggen',
    invalid_credentials: 'E-mail of wachtwoord ongeldig.',
    failed: 'Inloggen mislukt.'
  },

  orders: {
    title: 'Orders',
    new_order: 'Nieuwe order',
    customer: 'Klant',
    location: 'Locatie',
    service: 'Dienst',
    status: 'Status',
    updated_at: 'Laatste update'
  },

  order_detail: {
    title: 'Order #{{orderId}}',
    service: 'Dienst',
    status: 'Status',
    customer: 'Klant',
    sent_at: 'Verzonden op',
    content: 'Inhoud',
    container_bags: 'Containerbags',
    sets: 'Sets',
    bag_type: 'Type',
    bags_amount: 'Aantal bags',
    location: 'Locatie',
    pack: 'Inpakken',
    send: 'Verzenden',
    delete_order_title: 'Order #{{orderId}} verwijderen?',
    delete_order_description: 'Dit kan niet ongedaan worden gemaakt.',
    delete_order_failed: 'Order verwijderen mislukt.',
    send_dialog_title: 'Verzenden',
    track_and_trace: 'Track & Trace',
    send_order_failed: 'Order verzenden mislukt.',
    send_order_success: 'Verzonden',
    send_order_success_details: 'Order #{{orderId}} is aangemeld voor verzending.'
  },

  new_order: {
    title: 'Nieuwe order',
    service: 'Dienst',
    babo: 'BABO',
    baas: 'BAAS',
    customer: 'Klant',
    customer_has_no_locations: 'Deze klant heeft nog geen locaties.',
    add_location: 'Voeg een locatie toe',
    location: 'Locatie',
    content: 'Inhoud (sets)',
    type: 'Type',
    sets_amount: 'Sets',
    sets_amount_of_type: 'Aantal sets van type {{bagType}}',
    bags_amount: 'Bags',
    create: 'Aanmaken',
    create_order_failed: 'Order aanmaken mislukt.'
  },

  customers: {
    title: 'Klanten',
    new_customer: 'Nieuwe klant',
    name: 'Naam',
    updated_at: 'Laatste update'
  },

  customer_detail: {
    name: 'Naam',
    locations: 'Locaties',
    add_location: 'Locatie toevoegen',
    default_location_title: 'Nieuwe locatie',
    delete_location_title: '{{name}} verwijderen?',
    delete_location_description: 'Dit kan niet ongedaan worden gemaakt.',
    delete_customer_title: '{{name}} verwijderen?',
    delete_customer_description: 'Dit kan niet ongedaan worden gemaakt.',
    crm: 'CRM',
    add_crm: 'CRM-link toevoegen',
    bags_in_use: 'Bags in gebruik',
    no_bags_in_use: 'Geen bags in gebruik',
    bags_amount: 'Aantal',
    bag_type: 'Type',
    orders: 'Orders',
    order_info: '{{orderStatus}}: {{orderDate}}',
    no_orders: 'Geen orders',
    new_order: 'Nieuwe order',
    need_location_to_create_order_title: 'Geen locatie',
    need_location_to_create_order_description:
      'Voeg minstens één locatie toe om een order te kunnen maken.',
    update_name_failed: 'Naam wijzigen mislukt.',
    update_crm_url_failed: 'Crm-link wijzigen mislukt.',
    create_location_failed: 'Locatie aanmaken mislukt.',
    update_location_failed: 'Locatie aanpassen mislukt.',
    delete_location_failed: 'Locatie verwijderen mislukt.',
    delete_customer_failed: 'Klant verwijderen mislukt.'
  },

  new_customer: {
    name: 'Naam',
    name_placeholder: 'Nieuwe klant',
    create_customer_failed: 'Klant aanmaken mislukt.'
  },

  bags: {
    title: 'Bags',
    production: 'Productie',
    type: 'Type',
    customer: 'Klant',
    status: 'Status',
    updated_at: 'Laatste update'
  },

  bag_detail: {
    title: 'Bag #{{serialNumber}}',
    status: 'Status',
    type: 'Type',
    updated_at: 'Laatste update',
    customer: 'Klant',
    order: 'In order',
    history: 'Geschiedenis',
    clean: 'Schoonmaak',
    repair: 'Reparatie',
    write_off: 'Afschrijven',
    unpack_order_title: 'Let op: deze bag zit in een order',
    unpack_order_description:
      'De sets in deze order worden ontbonden en de order moet opnieuw worden ingepakt.',
    unpack_set_title: 'Let op: deze bag zit in een set',
    unpack_set_description: 'De set wordt ontbonden.',
    change_condition_failed: 'Bag-conditie wijzigen mislukt.',
    counter_in_order: 'Aantal keer verstuurd in orders'
  },

  bag_history: {
    title: 'Geschiedenis bag #{{serialNumber}}'
  },

  production: {
    title: 'Productie',
    new_batch: 'Nieuwe batch',
    batch_id: 'Batchnummer',
    total_bags_amount: 'Aantal bags',
    producer: 'Producent',
    created: 'Aangemaakt'
  },

  new_batch: {
    title: 'Nieuwe batch aanmaken',
    input_step_title: 'Inhoud',
    confirm_step_title: 'Bestelling bevestigen',
    result_step_title: '{{amount}} bags zijn aangemaakt:',
    type: 'Type',
    bags_amount: 'Aantal bags',
    bags_amount_of_type: 'Aantal bags van type {{bagType}}',
    select_less_bags_title: 'Selecteer maximaal {{maxAmount}} bags',
    select_less_bags_description:
      'Je hebt meer bags geselecteerd dan is toegestaan. Pas je bestelling aan.',
    download_label_data: 'Labels downloaden'
  },

  batch_detail: {
    title: 'Batch #{{id}}',
    created: 'Aangemaakt',
    total_bags_amount: 'Aantal bags',
    content: 'Inhoud',
    bag_type: 'Type',
    bags_amount: 'Aantal bags',
    download_label_data: 'Labels downloaden'
  },

  return_locations: {
    title: 'Inleverpunten',
    new_return_location: 'Nieuw inleverpunt',
    name: 'Naam',
    bags_amount: 'Aantal bags'
  },

  return_location_detail: {
    name: 'Naam',
    total_bags_amount: 'Aantal bags',
    bags_at_location: 'Bags',
    bags_amount: 'Aantal',
    bag_type: 'Type',
    no_bags_at_location: 'Geen bags',
    delete_return_location_title: '{{name}} verwijderen?',
    delete_return_location_description: 'Dit kan niet ongedaan worden gemaakt.',
    delete_return_location_failed: 'Inleverpunt verwijderen mislukt.',
    update_name_failed: 'Naam wijzigen mislukt.'
  },

  new_return_location: {
    name: 'Naam',
    name_placeholder: 'Nieuw inleverpunt',
    create_return_location_failed: 'Inleverpunt aanmaken mislukt.'
  },

  activity: {
    title: 'Activiteit'
  },

  scanner: {
    title: 'Scanner',
    subtitle: 'Scan een bag of kies een scanactie.',
    add_to_stock: 'Binnenscannen',
    pack: 'Inpakken',
    create_set: 'Sets maken',
    scanner_menu: 'Scannermenu',
    open_scanner_menu: 'Open scannermenu'
  },

  add_to_stock: {
    title: 'Binnenscannen',
    instructions: 'Scan een bag.',
    list_title: 'Gescande bags',
    no_bag: 'Deze bag is niet gevonden.',
    add_to_stock_failed: 'Binnenscannen mislukt.',
    bag_scanned: '{{type}} #{{serialNumber}} gescand',
    set_deleted: 'Let op: de volledige set is ontbonden.',
    order_unpacked: 'Let op: order #{{orderId}} moet opnieuw worden ingepakt.',
    unchanged: '(ongewijzigd)',
    invalid: 'Ongeldig',
    bag_type: 'Type',
    bag_status: 'Status',
    updated_at: 'Laatste update'
  },

  create_set: {
    title: 'Sets maken',
    instructions: 'Scan een bag',
    set_full: 'De set is vol. Kies [[global.finish]] om door te gaan.',
    cannot_add_container_bag: 'Containerbags kunnen niet aan een set worden toegevoegd.',
    wrong_status:
      'Bag #{{serialNumber}} heeft status {{status}}. Scan bags met status [[bag_status.ready]].',
    already_selected: 'Bag #{{serialNumber}} was al geselecteerd.',
    wrong_type: 'Bag #{{serialNumber}} ({{type}}) is niet het juiste type.',
    bag_selected: 'Bag #{{serialNumber}} geselecteerd',
    finished: 'Set compleet',
    pending_bags_amount: 'Scan nog {{bags_amount}} van type {{type}}.',
    finished_instructions: 'Kies [[global.finish]] om de set aan te maken.',
    result_dialog_title: 'Set gemaakt',
    result_dialog_description: 'Set gemaakt van {{amount}} bags met het type {{type}}.',
    list_title: 'Geselecteerde bags',
    bag_type: 'Type',
    bag_status: 'Status',
    updated_at: 'Laatste update',
    next_set: 'Volgende set'
  },

  pack_order: {
    title: 'Order #{{orderId}} inpakken',
    instructions_with_containerbags: 'Scan sets en containerbags.',
    instructions_without_containerbags: 'Scan sets.',
    instructions_scan_containerbags: 'Vervolgstap: scan de benodigde containerbag(s).',
    outside_bag_serial_number: '# (buitenste bag)',
    container_bags: 'Containerbags',
    order_full: 'De order is vol. Kies [[global.finish]] om door te gaan.',
    set_in_another_order: 'Deze set bevindt zich al in een andere order.',
    bag_not_in_set: 'Bag #{{serialNumber}} is geen onderdeel van een set.',
    set_already_selected: 'Deze set was al geselecteerd.',
    bag_type_not_pending: 'Geen sets (meer) nodig van type {{bagType}}.',
    cannot_add_container_bag: 'Aan deze order kunnen geen containerbags worden toegevoegd.',
    container_bag_in_another_order: 'Deze containerbag bevindt zich al in een andere order.',
    container_bag_wrong_status:
      'Containerbag #{{serialNumber}} heeft status {{status}}. Scan containerbags met status [[bag_status.ready]].',
    container_bag_already_selected: 'Deze containerbag was al geselecteerd.',
    container_bag_selected: 'Containerbag geselecteerd',
    set_selected: 'Set geselecteerd',
    finished: 'Order compleet',
    finished_instructions: 'Kies [[global.finish]] om het inpakken te voltooien.',
    result_dialog_title: 'Order ingepakt'
  },

  settings: {
    title: 'Instellingen',
    log_out: 'Uitloggen'
  }
}
