const shallowCopy = <T>(obj: T): T => {
  return Object.assign({}, obj)
}

// AllPaths is based on https://stackoverflow.com/a/73179989
type Dot<T extends string, U extends string> = '' extends T ? U : '' extends U ? T : `${T}.${U}`
type PathsToProps<T, V> = T extends V
  ? ''
  : {
      [K in Extract<keyof T, string>]: Dot<K, PathsToProps<T[K], V>>
    }[Extract<keyof T, string>]
export type AllPaths<T> = PathsToProps<T, string>

const getPropByPath = <T, P extends AllPaths<T>>(obj: T, path: P) => {
  // @ts-ignore: not sure how to solve this type issue:
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const result = (path as string).split('.').reduce((acc, cur) => acc?.[cur], obj)
  return result
}

const object = {
  shallowCopy,
  getPropByPath
}

export default object
