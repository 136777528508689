import object, {AllPaths} from 'utils/object'
import {NonEmptyArray} from 'utils/types'
import {nl} from './strings'

const EXTERNAL_PLACEHOLDER_REGEX = /{{(.+?)}}/g
const INTERNAL_PLACEHOLDER_REGEX = /\[\[(.+?)\]\]/g

const CURRENT_LANGUAGE = nl

const translate = <T extends Record<string, unknown>>(
  dictionary: T,
  key: AllPaths<T>,
  interpolations?: Record<string, unknown>
) => {
  const value = object.getPropByPath(dictionary, key)
  if (typeof value !== 'string') {
    throw new Error(`[i18n] No translation for key: ${key}`)
  }

  const valueWithInternalInterpolations = String(value).replaceAll(
    INTERNAL_PLACEHOLDER_REGEX,
    (placeholder, interpolationKey: string) => {
      // @ts-ignore: interpolations are untyped at the moment:
      const interpolation = object.getPropByPath(nl, interpolationKey)
      if (interpolation === undefined) {
        throw new Error(`[i18n] Missing interpolation: ${placeholder} in ${key}`)
      }
      return String(interpolation)
    }
  )

  const valueWithAllInterpolations = valueWithInternalInterpolations.replaceAll(
    EXTERNAL_PLACEHOLDER_REGEX,
    (placeholder, interpolationKey: string) => {
      const interpolation = interpolations?.[interpolationKey]
      if (interpolation === undefined) {
        throw new Error(`[i18n] Missing interpolation: ${placeholder} in ${key}`)
      }
      return String(interpolation)
    }
  )

  return valueWithAllInterpolations
}

export const getT = <T extends NonEmptyArray<keyof typeof CURRENT_LANGUAGE>>(
  ...namespaceKeys: T
) => {
  type CombinedNamespaces = (typeof nl)[T[number]]

  // Assertion is needed because the type cannot be inferred:
  const dictionary = namespaceKeys.reduce(
    (acc, cur) => ({...acc, ...CURRENT_LANGUAGE[cur]}),
    {}
  ) as CombinedNamespaces

  const t = (key: AllPaths<CombinedNamespaces>, interpolations?: Record<string, unknown>) =>
    translate(dictionary, key, interpolations)
  return t
}
